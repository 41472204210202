import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { DialogTitle, IconButton, DialogContent } from "@mui/material";
import { deviceSize } from "src/utils/deviceSize";

export const DialogHeader = muiStyled(DialogTitle)((props) => ({
  // backgroundColor: props.title === "true" ? "#2c4c80" : "",
  background: 'linear-gradient(0deg, #004B76 36%, #00E9CD 184%)',
  padding: '6px 24px',
  display: "flex",
  justifyContent: "center",
  fontWeight: 600,
}));

export const DialogHeaderName = styled.div`
  font-size: 28px;
  color: white;
`;

export const DialogIcon = muiStyled(IconButton)({
  position: 'absolute',
  right: '10px',
  top: '15px',
  width: "25px",
  height: "25px",
});

export const DialogContentWrapper = muiStyled(DialogContent)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  backgroundColor: 'white',
});

export const DialogContentTitle = styled.div`
  width: 100%;
  height: 34px;
  font-weight: 600;
  font-size: 28px;
  color: #18bbf3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CovertWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const CovertItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CovertItemImage = styled.img`
  width: 150px;
  height: 150px;
  background-color: #fff;
  border: 2px solid #18bbff;
  border-radius: 5px;
  object-fit: contain;
  margin: 10px 0;
`;

export const CovertDetail = styled.div`
  color: #007DBE;
  margin-left: 15px;
`;

export const CovertItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: ${deviceSize.mobile}) {
    height: 40px;
    margin-bottom: 0px;
  }
`;

export const CovertItemTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  // color: #f5f5f5;
  font-size: 14px;
  p {
    width: 200px;
    margin-left: 8px;
  }
`;

export const CovertItemText = styled.p`
  font-size: 14px;
`;

export const CooperateWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const CooperateTitle = styled.div`
  color: #007DBE;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const CooperateContentWrapper = styled.div`
  text-align: center;
  color: #007DBE;

  p {
    font-weight: 500;
    line-height: 28px;
  }
`;
