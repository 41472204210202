import styled from "styled-components";
import headerBg from "src/assets/header/header.jpg";
import { deviceSize } from "src/utils/deviceSize";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  // background-image: url(${headerBg});
  background-color: #009BD4;
  @media (max-width: ${deviceSize.mobile}) {
    height: 50px;
    justify-content: flex-start;

  }
`;

export const HeaderLogo = styled.img`
  width: 183px;
  height: 59px;
  @media (max-width: ${deviceSize.mobile}) {
    width: 128px;
    height: 41px;
  }
`;

export const HeaderTitle = styled.div`
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-left: 5px;
  text-shadow: 0px 1px 0px #22F8DC;

  @media (max-width: ${deviceSize.mobile}) {
    font-size: 26px;
  }
`;

export const HeaderButtonWrapper = styled.div`
  position: absolute;
  right: 5%;
  padding: 6px;
  color: #009BD4;
  background-color: white;
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: ${deviceSize.mobile}) {
    font-size: 12px;
    right: 5%;
  }
`;
