import styled from "styled-components";
import { deviceSize } from "src/utils/deviceSize";

export const CustomButtonWrapper = styled.button((props) => ({
  width: "137px",
  height: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#f3f3f3",
  fontSize: "16px",
  cursor: "pointer",
  border: "none",
  borderRadius: "5px",
  background: props.secondary
    ? `#007DBE`
    : `linear-gradient(180deg, #003F6E 0%, rgba(1, 64, 111, 0.994792) 0.01%, rgba(24, 187, 254, 0.984528) 0.02%, #003F6E 100%)`,
  "&:hover": {
    background: "#2C4C80",
  },
  marginBottom: props.mb,
}));

export const CustomLgButtonWrapper = styled.button((props) => ({
  width: "100%",
  height: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // borderRadius: " 0px 0px 10px 10px",
  cursor: "pointer",
  border: "none",
  fontSize: "16px",
  color: "#f5f5f5",
  background: props.secondary
    ? `linear-gradient(180deg, #0851C7 0%, #051228 100%)`
    : `#009BD4`,
  "&:hover": {
    background: "#2C4C80",
  },
  marginBottom: props.mb,
}));

export const CustomSmButtonWrapper = styled.button`
  width: 78px;
  height: 30px;
  font-size: 16px;
  color: #f5f5f5;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background: ${(props) =>
    props.secondary
      ? `linear-gradient(180deg, #0851C7 0%, #051228 100%)`
      : `linear-gradient(180deg, #003F6E 0%, rgba(1, 64, 111, 0.994792) 0.01%, rgba(24, 187, 254, 0.984528) 0.02%, #003F6E 100%)`};
  &:hover {
    background: "#2C4C80";
  }
  @media (max-width: ${deviceSize.mobile}) {
    width: 72px;
    height: 24px;
    font-size: 14px;
  }
  @media (max-width: 414px) {
    font-size: 10px;
  }
`;

export const CustomButtonIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
