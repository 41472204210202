import styled from "styled-components";
import { deviceSize } from "src/utils/deviceSize";

export const BannerContainer = styled.div`
  width: 100%;
  max-height: 590px;
  margin-bottom: 34px;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 590px;
  pointer-events: none;
`;
