const bannerData = [
  {
    name: "banner1",
    imgSrc: require("src/assets/banner/bn1.jpg"),
  },
  {
    name: "banner2",
    imgSrc: require("src/assets/banner/bn2.jpg"),
  },
  {
    name: "banner3",
    imgSrc: require("src/assets/banner/bn3.jpg"),
  },
  {
    name: "banner4",
    imgSrc: require("src/assets/banner/bn4.jpg"),
  },
  {
    name: "banner5",
    imgSrc: require("src/assets/banner/bn5.jpg"),
  },
];

export default bannerData;
