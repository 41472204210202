import { PublicLayout, MainLayout } from "./components/layout";
import Header from "./components/shared/header/";
import Footer from "./components/shared/footer";
import Banner from "./components/banner";
import Products from "./components/products";

function App() {
  return (
    <PublicLayout className="App">
      <Header />
      <MainLayout>
        <Banner />
        <Products />
      </MainLayout>
      <Footer />
    </PublicLayout>
  );
}

export default App;
