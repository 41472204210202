import styled from "styled-components";
import footerBg from "src/assets/footer/footer.jpg";
import { deviceSize } from "src/utils/deviceSize";

export const FooterContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DCDCDC;
  background-position: center;
  @media (max-width: ${deviceSize.mobile}) {
    height: 50px;
  }
`;

export const SponsorButton = styled.button`
  height: 48px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: #959595;
  font-size: 12px;
  background: none;
`;
