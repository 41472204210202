const tabData = [
  {
    titleName: "本季限定",
    icon: require("src/assets/tabs/tabs1.png"),
    content: [
      {
        name: "【飛達娛樂】限定中盲盒",
        imgSrc: require("src/assets/products/FeidaBox.jpg"),
        price: "88,888",
        exchangeTime: 168,
        originPrice: "無價",
      },
      {
        name: "【飛達娛樂】限定大盲盒",
        imgSrc: require("src/assets/products/FeidaBox.jpg"),
        price: "888,888",
        exchangeTime: 888,
        originPrice: "無價",
      },
      {
        name: "【飛達娛樂】限定巨無霸盲盒",
        imgSrc: require("src/assets/products/FeidaBox.jpg"),
        price: "8,888,888",
        exchangeTime: 3688,
        originPrice: "無價",
      },
      {
        name: "【OSIM】V手天王按摩椅 OS-890",
        imgSrc: require("src/assets/products/OS-890.jpg"),
        price: "1,240,800",
        exchangeTime: 58,
        originPrice: "88,000",
      },
      {
        name: "【德國百靈】 新5系列免拆快洗電鬍刀",
        imgSrc: require("src/assets/products/MonthOnly/51-M4500CS.jpg"),
        price: "97,320",
        exchangeTime: 38,
        originPrice: "6,488",
      },
      {
        name: "【康生】泰式SPA肩頸紓壓按摩椅墊-黑色",
        imgSrc: require("src/assets/products/MonthOnly/CM-2022-3.jpg"),
        price: "89,700",
        exchangeTime: 28,
        originPrice: "5,980",
      },
      {
        name: "【歐樂B】3D電動牙刷-曜石黑",
        imgSrc: require("src/assets/products/MonthOnly/PRO4-BLACK.jpg"),
        price: "83,584",
        exchangeTime: 98,
        originPrice: "5,499",
      },
      {
        name: "【PHILIPS】5.1.2無線重低音家庭劇院",
        imgSrc: require("src/assets/products/MonthOnly/TAB8967-96.jpg"),
        price: "463,610",
        exchangeTime: 25,
        originPrice: "25,900",
      },
      {
        name: "【SONY】 PS5 光碟版主機",
        imgSrc: require("src/assets/products/ps5.jpg"),
        price: "281,280",
        exchangeTime: 98,
        originPrice: "17,580",
      },
      {
        name: "【SAMSUNG三星】 Odyssey G5 27吋 1000R 曲面螢幕",
        imgSrc: require("src/assets/products/MonthOnly/SAMSUNG-Odyssey_.jpg"),
        price: "84,651",
        exchangeTime: 98,
        originPrice: "6,090",
      },
      {
        name: "【華碩- 寂靜鬥王】 i5十核獨顯SSD電腦(i5-13400F/16G/1T/GTX 1650/512G_M2)",
        imgSrc: require("src/assets/products/MonthOnly/i5-13400F.jpg"),
        price: "421,750",
        exchangeTime: 98,
        originPrice: "24,100",
      },
      {
        name: "【Gravastar】Mars Pro 藍牙音響-暗物質造型",
        imgSrc: require("src/assets/products/MonthOnly/MarsPro-Bluetooth.jpg"),
        price: "91,509",
        exchangeTime: 98,
        originPrice: "6,490",
      },
      {
        name: "【尖叫能量】電力瓶PD快充行動電源Lightning/TypeC任選",
        imgSrc: require("src/assets/products/MonthOnly/screamenergy.jpg"),
        price: "17,760",
        exchangeTime: 98,
        originPrice: "1,480",
      },
      {
        name: "【Gravastar】 SIRIUS 無線耳機 (星艦銀)",
        imgSrc: require("src/assets/products/SIRIU_PRO.jpg"),
        price: "35,370",
        exchangeTime: 36,
        originPrice: "2,700",
      },
      {
        name: "【FUJIFILM 富士】Instax MINI 12 拍立得相機",
        imgSrc: require("src/assets/products/MonthOnly/FUJI- Instax-mini12.jpg"),
        price: "38,388",
        exchangeTime: 58,
        originPrice: "2,990",
      },
    ],
  },
  {
    titleName: "客製化",
    icon: require("src/assets/tabs/tabs7.png"),
    content: [
      {
        name: "東南亞國家來回機票",
        imgSrc: require("src/assets/products/MonthOnly/Air-tickets.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "Hermes 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/hermes.jpeg"),
        price: "客製化顆數",
        exchangeTime: 98,
        originPrice: "浮動",
      },
      {
        name: "火辣嫩模商務伴遊",
        imgSrc: require("src/assets/products/LuxuryProducts/18.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "黃金 1 錢",
        imgSrc: require("src/assets/products/Gold.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "黃金 5 錢",
        imgSrc: require("src/assets/products/Gold.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "黃金 1 兩",
        imgSrc: require("src/assets/products/Gold.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "沛納海手錶",
        imgSrc: require("src/assets/products/LuxuryProducts/panerai.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "勞力士手錶",
        imgSrc: require("src/assets/products/LuxuryProducts/rolex.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "百達翡麗手錶",
        imgSrc: require("src/assets/products/LuxuryProducts/Patek-Philippe.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "GOGORO 機車",
        imgSrc: require("src/assets/products/GOGORO.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "76,980",
      },
      {
        name: "百萬進口豪車",
        imgSrc: require("src/assets/products/LuxuryProducts/porsche.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "CHANEL 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/chnel.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "LV 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/lv.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "GUCCI 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/gucci.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "DIOR 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/dior.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "CELINE 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/celine.jpg"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "Balenciaga 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/balenciaga.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
      {
        name: "LOEWE 系列精品包包",
        imgSrc: require("src/assets/products/LuxuryProducts/loewe.png"),
        price: "客製化顆數",
        exchangeTime: 88,
        originPrice: "浮動",
      },
    ],
  },
  // {
  //   titleName: "輕奢精品",
  //   icon: require("src/assets/tabs/tabs2.png"),
  //   content: [
  //     {
  //       name: "Hermes 愛馬仕包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/hermes.jpeg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "火辣嫩模商務伴遊",
  //       imgSrc: require("src/assets/products/LuxuryProducts/18.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "黃金 1 錢",
  //       imgSrc: require("src/assets/products/LuxuryProducts/gold.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "黃金 5 錢",
  //       imgSrc: require("src/assets/products/LuxuryProducts/gold.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "黃金 1 兩",
  //       imgSrc: require("src/assets/products/LuxuryProducts/gold.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "沛納海手錶",
  //       imgSrc: require("src/assets/products/LuxuryProducts/panerai.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "勞力士手錶",
  //       imgSrc: require("src/assets/products/LuxuryProducts/rolex.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "百達翡麗手錶",
  //       imgSrc: require("src/assets/products/LuxuryProducts/Patek-Philippe.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "GOGORO 機車",
  //       imgSrc: require("src/assets/products/LuxuryProducts/gogoro.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "百萬進口豪車",
  //       imgSrc: require("src/assets/products/LuxuryProducts/porsche.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "CHANEL 香奈兒 包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/chnel.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "LV 系列精品包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/lv.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "GUCCI 系列精品包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/gucci.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "DIOR 系列精品包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/dior.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "CELINE 賽琳 系列精品包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/celine.jpg"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "巴黎世家系列精品包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/balenciaga.png"),
  //       price: "客製化顆數",
  //     },
  //     {
  //       name: "LOEWE 羅威系列精品包包",
  //       imgSrc: require("src/assets/products/LuxuryProducts/loewe.png"),
  //       price: "客製化顆數",
  //     },
  //   ],
  // },
  {
    titleName: "3C電器好禮",
    icon: require("src/assets/tabs/tabs3.png"),
    content: [
      {
        name: "【Dyson】 Supersonic™ 吹風機 HD15 桃紅色",
        imgSrc: require("src/assets/products/3C/dyson-hair.jpg"),
        price: "220,460",
        exchangeTime: 16,
        originPrice: "14,600",
      },
      {
        name: "【Dyson】 無線吸塵器",
        imgSrc: require("src/assets/products/3C/dyson-ground.jpg"),
        price: "313,740",
        exchangeTime: 18,
        originPrice: "18,900",
      },
      {
        name: "【Dyson】 空氣清淨機",
        imgSrc: require("src/assets/products/3C/dyson-air-clear.jpg"),
        price: "328,350",
        exchangeTime: 16,
        originPrice: "19,900",
      },
      {
        name: "【Nintendo】SWITCH 遊戲機",
        imgSrc: require("src/assets/products/3C/switch.jpg"),
        price: "152,586",
        exchangeTime: 108,
        originPrice: "10,380",
      },
      {
        name: "【JVC】 65吋 Apple認證4K HDR 飛輪體感連網液晶顯示器65TG",
        imgSrc: require("src/assets/products/3C/jvc-tv.jpg"),
        price: "231,826",
        exchangeTime: 15,
        originPrice: "15,988",
      },
      {
        name: "【LG】圓柱型空氣清淨機",
        imgSrc: require("src/assets/products/3C/3c-14.jpg"),
        price: "194,790",
        exchangeTime: 10,
        originPrice: "12,900",
      },
      {
        name: "【小米】免洗掃拖機器人Pro",
        imgSrc: require("src/assets/products/3C/xiaomi-rebot.jpg"),
        price: "445,365",
        exchangeTime: 68,
        originPrice: "32,990",
      },
      {
        name: "【小米】米家空氣淨化器4Pro",
        imgSrc: require("src/assets/products/3C/xiaomi-air-clear.png"),
        price: "166,983",
        exchangeTime: 99,
        originPrice: "9,999",
      },
      {
        name: "APPLE TV",
        imgSrc: require("src/assets/products/3C/apple-tv-4k.jpg"),
        price: "76,583",
        exchangeTime: 10,
        originPrice: "5,590",
      },
      {
        name: "APPLE 耳機 (第三代)",
        imgSrc: require("src/assets/products/3C/3c-2.jpg"),
        price: "79,378",
        exchangeTime: 188,
        originPrice: "5,590",
      },
      {
        name: "Apple Watch Series 9",
        imgSrc: require("src/assets/products/3C/3c-3.jpg"),
        price: "217,350",
        exchangeTime: 38,
        originPrice: "13,500",
      },
      {
        name: "iPhone 15 128G",
        imgSrc: require("src/assets/products/3C/3c-4.jpg"),
        price: "442,520",
        exchangeTime: 28,
        originPrice: "29,900",
      },
      {
        name: "iPhone 15 Plus 128G",
        imgSrc: require("src/assets/products/3C/3c-5.jpg"),
        price: "490,790",
        exchangeTime: 100,
        originPrice: "32,900",
      },
      {
        name: "iPhone 15 256G",
        imgSrc: require("src/assets/products/3C/3c-6.jpg"),
        price: "451,490",
        exchangeTime: 108,
        originPrice: "29,900",
      },
      {
        name: "iPhone 15 Pro 128G",
        imgSrc: require("src/assets/products/3C/3c-7.jpg"),
        price: "557,190",
        exchangeTime: 288,
        originPrice: "36,900",
      },

      {
        name: "iPad Pro Wi-Fi 128G",
        imgSrc: require("src/assets/products/3C/3c-11.jpg"),
        price: "421,290",
        exchangeTime: 28,
        originPrice: "27,900",
      },
      {
        name: "【東方不敗】電動麻將桌送四張卡夢椅",
        imgSrc: require("src/assets/products/MonthOnly/3c-12.jpg"),
        price: "528,960",
        exchangeTime: 98,
        originPrice: "34,800",
      },
      {
        name: "【SMASMALL 昔馬】全機水洗充電式雙刀頭電動刮鬍刀(精緻禮盒組-三色可選)",
        imgSrc: require("src/assets/products/MonthOnly/smasmall.png"),
        price: "34,830",
        exchangeTime: 16,
        originPrice: "2580",
      },
      {
        name: "【Michelin 米其林】數位高速自動打氣機",
        imgSrc: require("src/assets/products/MonthOnly/autopump.jpg"),
        price: "53,439",
        exchangeTime: 12,
        originPrice: "3,790",
      },
      {
        name: "【Razer 雷蛇】Ornata V3 雨林狼蛛 V3 中文有線鍵盤",
        imgSrc: require("src/assets/products/MonthOnly/razer-keyboard.png"),
        price: "28,689",
        exchangeTime: 36,
        originPrice: "2,190",
      },
      {
        name: "【Razer 雷蛇】Kraken V3 X 北海巨妖 V3 X 有線電競耳機",
        imgSrc: require("src/assets/products/MonthOnly/3c-13.jpg"),
        price: "28,689",
        exchangeTime: 28,
        originPrice: "2,190",
      },
    ],
  },
  {
    titleName: "媽媽最愛",
    icon: require("src/assets/tabs/tabs4.png"),
    content: [
      {
        name: "Doris.in 植萃洗衣膠囊30顆*3 (小蒼蘭、粉薔薇可選)",
        imgSrc: require("src/assets/products/MotherFavorite/original.jpg"),
        price: "11,220",
        exchangeTime: 66,
        originPrice: "1,020",
      },
      {
        name: "【P&G 寶僑】洗衣香香豆 罐裝 470ML * 4",
        imgSrc: require("src/assets/products/MotherFavorite/p-g.png"),
        price: "18,816",
        exchangeTime: 66,
        originPrice: "1,680",
      },
      {
        name: "【好神拖】超值回饋輕巧旋轉拖把(2拖1桶4布)",
        imgSrc: require("src/assets/products/MotherFavorite/supamop.png"),
        price: "14,160",
        exchangeTime: 68,
        originPrice: "1,180",
      },
      {
        name: "【HYD】絕美經典氣炸鍋",
        imgSrc: require("src/assets/products/HYD.jpg"),
        price: "35,760",
        exchangeTime: 68,
        originPrice: "2,980",
      },
      {
        name: "【Kolin 歌林】折疊滾輪足浴機",
        imgSrc: require("src/assets/products/Kolin.jpg"),
        price: "22,232",
        exchangeTime: 18,
        originPrice: "1,588",
      },
      {
        name: "【G-PLUS 拓勤】GP刷豹 音波電動牙刷",
        imgSrc: require("src/assets/products/MotherFavorite/teeth.jpg"),
        price: "22,222",
        exchangeTime: 388,
        originPrice: "1,580",
      },
      {
        name: "90x110cm 大號垃圾袋 50 入 * 2",
        imgSrc: require("src/assets/products/MotherFavorite/trash-bag.png"),
        price: "17,610",
        exchangeTime: 218,
        originPrice: "1,198",
      },
      {
        name: "【KOSE 高絲】雪肌精 舒芙蕾精華乳 140ml",
        imgSrc: require("src/assets/products/kose140.jpg"),
        price: "17,810",
        exchangeTime: 388,
        originPrice: "1,200",
      },
      {
        name: "【CookPower 鍋寶】鈦頂極不沾鍋平底鍋30CM",
        imgSrc: require("src/assets/products/MotherFavorite/cookpower-pan.png"),
        price: "28,540",
        exchangeTime: 188,
        originPrice: "1,980",
      },
      {
        name: "10/10 HOPE 香氛蠟燭",
        imgSrc: require("src/assets/products/MotherFavorite/neom.png"),
        price: "26,460",
        exchangeTime: 88,
        originPrice: "1,800",
      },
      {
        name: "【幫寶適】日本原裝幫寶適尿布 L 120 片",
        imgSrc: require("src/assets/products/MotherFavorite/pampers.jpg"),
        price: "19,051",
        exchangeTime: 88,
        originPrice: "1,296",
      },
      {
        name: "【CIRCLE】德國 CIRCLE TREVISO 4S 嬰兒車",
        imgSrc: require("src/assets/products/MotherFavorite/baby-car.jpg"),
        price: "90,000",
        exchangeTime: 26,
        originPrice: "6,000",
      },
      {
        name: "【KitchenCraft】刀架 + 刀具 5 件組",
        imgSrc: require("src/assets/products/MotherFavorite/knife.jpg"),
        price: "69,750",
        exchangeTime: 38,
        originPrice: "4,650",
      },
      {
        name: "【Johnsons 嬌生】嬰兒純水柔濕巾 (90 片 x 24入 )",
        imgSrc: require("src/assets/products/MotherFavorite/johnsons.jpg"),
        price: "23,400",
        exchangeTime: 388,
        originPrice: "1,560",
      },
      {
        name: "【理膚寶水】B5 瞬效全面修復精華 30ML",
        imgSrc: require("src/assets/products/MotherFavorite/laroche-posay-b5.jpg"),
        price: "23,798",
        exchangeTime: 188,
        originPrice: "1,630",
      },
      {
        name: "【理膚寶水】理必佳清爽保溼乳 400ML",
        imgSrc: require("src/assets/products/MotherFavorite/laroche-posay.jpg"),
        price: "24,588",
        exchangeTime: 136,
        originPrice: "1,748",
      },
      {
        name: "【日本IRIS】史上最強大拍5.0-FAC4除蟎機全面進化14000次拍打(銀色)",
        imgSrc: require("src/assets/products/MotherFavorite/iris.png"),
        price: "105,549",
        exchangeTime: 168,
        originPrice: "6,990",
      },
      {
        name: "【TATUNG 大同】20公升燒烤微波爐",
        imgSrc: require("src/assets/products/MotherFavorite/TMO-20FEA.jpg"),
        price: "47,212",
        exchangeTime: 16,
        originPrice: "4,290",
      },
    ],
  },
  {
    titleName: "日常用品",
    icon: require("src/assets/tabs/tabs5.png"),
    content: [
      {
        name: "台鹽海洋鹼性離子水 600ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/life-1.jpg"),
        price: "6,496",
        exchangeTime: 166,
        originPrice: "580",
      },
      {
        name: "台鹽海洋鹼性離子水 850ML * 20 入",
        imgSrc: require("src/assets/products/DailySupplies/life-1.jpg"),
        price: "5,040",
        exchangeTime: 12,
        originPrice: "420",
      },
      {
        name: "台鹽海洋鹼性離子水 1500ML * 12 入",
        imgSrc: require("src/assets/products/DailySupplies/life-1.jpg"),
        price: "5,040",
        exchangeTime: 16,
        originPrice: "420",
      },
      {
        name: "舒跑鹼性離子水 850ML * 20 入",
        imgSrc: require("src/assets/products/DailySupplies/supao-850ML.jpg"),
        price: "4,850",
        exchangeTime: 11,
        originPrice: "390",
      },
      {
        name: "舒跑鹼性離子水 1500ML * 12 入",
        imgSrc: require("src/assets/products/DailySupplies/supao-1500ML.jpg"),
        price: "6,780",
        exchangeTime: 10,
        originPrice: "696",
      },
      {
        name: "統一 PH9.0 鹼性離子水 800ML * 20 入",
        imgSrc: require("src/assets/products/DailySupplies/toni-water.png"),
        price: "5,040",
        exchangeTime: 20,
        originPrice: "420",
      },
      {
        name: "YOI 鹼性水 600mlx24入/箱",
        imgSrc: require("src/assets/products/DailySupplies/life-2.jpg"),
        price: "5,540",
        exchangeTime: 15,
        originPrice: "520",
      },
      {
        name: "奧利多水 585ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/oligo-water.jpg"),
        price: "7,150",
        exchangeTime: 27,
        originPrice: "720",
      },
      {
        name: "伯朗絲絨奶茶 580ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/brown-milktea.png"),
        price: "8,250",
        exchangeTime: 18,
        originPrice: "840",
      },
      {
        name: "金車礦沛檸檬氣泡水 585ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/crystal-vally.png"),
        price: "6,350",
        exchangeTime: 16,
        originPrice: "600",
      },
      {
        name: "得意衛生紙 100抽 * 84 包",
        imgSrc: require("src/assets/products/DailySupplies/deri-paper.jpg"),
        price: "10,580",
        exchangeTime: 17,
        originPrice: "1,085",
      },
      {
        name: "每朝健康綠茶/紅茶 650ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/life-3.jpg"),
        price: "8,250",
        exchangeTime: 16,
        originPrice: "840",
      },
      {
        name: "每朝健康綠茶/紅茶 900ML * 12 入",
        imgSrc: require("src/assets/products/DailySupplies/life-3.jpg"),
        price: "5,692",
        exchangeTime: 12,
        originPrice: "495",
      },
      {
        name: "每朝健康雙纖無糖綠茶 650ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/asatea.png"),
        price: "8,250",
        exchangeTime: 13,
        originPrice: "840",
      },
      {
        name: "麥香奶茶 300ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/maixian-milktea.jpg"),
        price: "3,960",
        exchangeTime: 57,
        originPrice: "360",
      },
      {
        name: "麥香紅茶 300ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/maixian-black.jpg"),
        price: "3,960",
        exchangeTime: 35,
        originPrice: "360",
      },
      {
        name: "麥香綠茶 300ML * 24 入",
        imgSrc: require("src/assets/products/DailySupplies/maixian-green.jpg"),
        price: "3,960",
        exchangeTime: 43,
        originPrice: "360",
      },
      {
        name: "精選高級紅茶禮盒組(黃金金)",
        imgSrc: require("src/assets/products/MonthOnly/tea-box.jpg"),
        price: "31,920",
        exchangeTime: 35,
        originPrice: "2,660",
      },
      {
        name: "女性之居家老王 山茶花SAZANKA 熱感潮吹液 30mL",
        imgSrc: require("src/assets/products/MonthOnly/life-4.jpg"),
        price: "17,880",
        exchangeTime: 11,
        originPrice: "1,480",
      },
      {
        name: "男性之居家小三 Dragon eye 龍魂特別版+潤感水星 300ml",
        imgSrc: require("src/assets/products/MonthOnly/life-5.jpg"),
        price: "23,280",
        exchangeTime: 16,
        originPrice: "1,940",
      },
    ],
  },
  {
    titleName: "美食電影票券",
    icon: require("src/assets/tabs/tabs6.png"),
    content: [
      {
        name: "500元商品券",
        imgSrc: require("src/assets/products/coupon/500.jpg"),
        price: "6,500",
        exchangeTime: 75,
        originPrice: "500",
      },
      {
        name: "600元商品券",
        imgSrc: require("src/assets/products/coupon/600.jpg"),
        price: "7,800",
        exchangeTime: 69,
        originPrice: "600",
      },
      {
        name: "700元商品券",
        imgSrc: require("src/assets/products/coupon/700.jpg"),
        price: "8,750",
        exchangeTime: 53,
        originPrice: "700",
      },
      {
        name: "800元商品券",
        imgSrc: require("src/assets/products/coupon/800.jpg"),
        price: "9,600",
        exchangeTime: 31,
        originPrice: "800",
      },
      {
        name: "7-11 500 元禮券*3",
        imgSrc: require("src/assets/products/coupon/7-11.png"),
        price: "17,250",
        exchangeTime: 138,
        originPrice: "1,500",
      },
      {
        name: "7-11 1000 元禮券",
        imgSrc: require("src/assets/products/coupon/7-11.png"),
        price: "11,500",
        exchangeTime: 128,
        originPrice: "1,000",
      },
      {
        name: "家樂福禮券 500 元(目前缺貨，兌換前可先詢問是否有貨)*3",
        imgSrc: require("src/assets/products/coupon/carrefour.jpg"),
        price: "17,250",
        exchangeTime: 168,
        originPrice: "1,500",
      },
      {
        name: "家樂福禮券 1000 元(目前缺貨，兌換前可先詢問是否有貨)",
        imgSrc: require("src/assets/products/coupon/carrefour.jpg"),
        price: "11,500",
        exchangeTime: 258,
        originPrice: "1,000",
      },
      {
        name: "苗栗泰安日出溫泉度假飯店雙人房",
        imgSrc: require("src/assets/products/coupon/sunrise-logo.png"),
        price: "11,588",
        exchangeTime: 23,
        originPrice: "999",
      },
      {
        name: "四重溪牡丹風情溫泉行館雙人湯屋 60 分鐘泡湯券",
        imgSrc: require("src/assets/products/coupon/mudan.jpg"),
        price: "13,340",
        exchangeTime: 23,
        originPrice: "1,150",
      },
      {
        name: "威秀電影票 X 2 張",
        imgSrc: require("src/assets/products/coupon/ViewShow.png"),
        price: "7,820",
        exchangeTime: 16,
        originPrice: "680",
      },
      {
        name: "秀太電影票 X 2 張",
        imgSrc: require("src/assets/products/coupon/showtime-movie.jpg"),
        price: "7,130",
        exchangeTime: 19,
        originPrice: "620",
      },
      {
        name: "國賓電影票 X 2 張",
        imgSrc: require("src/assets/products/coupon/ogp.jpg"),
        price: "7,820",
        exchangeTime: 28,
        originPrice: "680",
      },
      {
        name: "新光電影票 X 2 張",
        imgSrc: require("src/assets/products/coupon/sinkong-movie.jpg"),
        price: "7,130",
        exchangeTime: 11,
        originPrice: "620",
      },
    ],
  },
];

export default tabData;
