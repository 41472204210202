import React, { useState } from "react";
import { HeaderContainer, HeaderLogo, HeaderTitle, HeaderButtonWrapper } from "./index.style";
import headerLogo from "src/assets/header/header-logo.png";
import CooperateButton from "src/components/shared/buttons/CustomSmButton";
import CooperateDialog from "src/components/shared/popup/CustomDialog";
import HeaderCooperate from "src/components/shared/popup/CooperateDialogContent";
import footerLogo from "src/assets/footer/FEIDA-LOGO.png";

function Header() {
  const [cooperateOpen, setCooperateOpen] = useState(false);

  const handleCooperateOpen = () => setCooperateOpen(true);
  const handleCooperateClose = () => setCooperateOpen(false);

  return (
    <HeaderContainer>
      {/* <HeaderLogo src={headerLogo} /> */}
      <img src={footerLogo} alt="LOGO" width={80} height={45} />
      <HeaderTitle>
        飛達商城
      </HeaderTitle>

      <HeaderButtonWrapper onClick={handleCooperateOpen}>
        合作結盟說明
      </HeaderButtonWrapper>

      <CooperateDialog
        title='合作結盟使用說明'
        open={cooperateOpen}
        onClose={handleCooperateClose}
        handleClose={handleCooperateClose}
        width="380px"
        height="230px"
      >
        <HeaderCooperate lastDialog={handleCooperateClose} />
      </CooperateDialog>
    </HeaderContainer>
  );
}

export default Header;
