import React, { useState } from "react";
import PropTypes from "prop-types";
import { ProductsLayout, TabContainer, TabImage, TabButtons, TabPanelWrapper, TabBackground } from "./index.style";
import ProductCard from "./ProductCard";
import tabData from "src/common/tabData";
import Grid from "@mui/material/Grid";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <TabPanelWrapper role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </TabPanelWrapper>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Products() {
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => setValue(newValue);

  return (
    <ProductsLayout>
      <TabBackground>
        <TabContainer variant="scrollable" indicatorColor={""} value={value} onChange={handleChange}>
          {tabData.map((item) => (
            <TabButtons
              key={item.titleName}
              label={item.titleName}
              icon={<TabImage src={item.icon} alt="tab icon" />}
            />
          ))}
        </TabContainer>
      </TabBackground>
      {tabData.map((item, index) => (
        <TabPanel key={item.titleName} value={value} index={index}>
          <Grid container rowGap={4} style={{ margin: "20px 0" }}>
            {item.content.map((item) => (
              <Grid item key={item.name} xs={6} lg={4} xl={3} style={{ display: "flex", justifyContent: "center" }}>
                <ProductCard image={item.imgSrc} name={item.name} price={item.price} originPrice={item.originPrice} exchangeTime={item.exchangeTime} url={item.url ? item.url : null} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </ProductsLayout>
  );
}

export default Products;
