import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@mui/material";
import { DialogHeader, DialogHeaderName, DialogIcon, DialogContentWrapper } from "./index.style";
import cancelIcon from "src/assets/modal/ph_x-bold.png";

function CustomDialog(props) {
  const { children, open, handleClose, width, height, title } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: `${width}`,
          height: `${height}`,
          // border: "1px solid #18bbfe",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #1d3762 0%, #051228 100%)",
          border: '#22F8DC 1px solid'

        },
      }}
    >
      <DialogHeader title={typeof title === "string" ? "false" : "true"}>
        {title !== undefined ? <DialogHeaderName>{title}</DialogHeaderName> : <></>}
        <DialogIcon onClick={handleClose}>
          <img src={cancelIcon} alt="cancel" />
        </DialogIcon>
      </DialogHeader>
      <DialogContentWrapper>{children}</DialogContentWrapper>
    </Dialog>
  );
}

export default CustomDialog;

CustomDialog.prototype = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
