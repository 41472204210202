import React from "react";
import { CustomLgButtonWrapper, CustomButtonIcon } from "./index.style";

function CustomLgButton(props) {
  const { name, secondary, image, handleClick } = props;
  return (
    <>
      {secondary ? (
        <CustomLgButtonWrapper secondary onClick={handleClick}>
          <CustomButtonIcon src={image} />
          {name}
        </CustomLgButtonWrapper>
      ) : (
        <CustomLgButtonWrapper onClick={handleClick}>
          <CustomButtonIcon src={image} />
          {name}
        </CustomLgButtonWrapper>
      )}
    </>
  );
}

export default CustomLgButton;
