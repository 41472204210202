import React, { useRef, useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { BannerContainer, BannerImage } from "./index.style";
import bannerData from "src/common/bannerData";
import Carousel from "react-material-ui-carousel";
import { deviceSize } from "src/utils/deviceSize";

function Banner() {
  const isMobile = useMediaQuery(`(max-width: ${deviceSize.mobile})`);
  const [height, setHeight] = useState(0);
  const image = useRef(null);

  function resize () {
    if (window.innerWidth >= 1280) {
      setHeight(590);
      return;
    }

    setHeight(window.innerWidth / 13 * 6);
  }
  useEffect(() => {
    resize();
  }, []);
  window.addEventListener('resize', () => {
    if (!image) { return; }

    resize();
  });
  return (
    <BannerContainer>
      <Carousel
        autoPlay
        swipe
        navButtonsAlwaysInvisible
        height={height}
        interval={6000}
        duration={700}
        animation="slide"
        indicatorIconButtonProps={{
          style: {
            color: "rgba(24, 187, 254, 0.4)",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#18BBFE",
          },
        }}
      >
        {bannerData.map((item) => (
          <BannerImage key={item.name} src={item.imgSrc} alt={item.name}
            ref={image}
          />
        ))}
      </Carousel>
    </BannerContainer>
  );
}

export default Banner;
