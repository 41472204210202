import styled from "styled-components";

export const CooperateWrapper = styled.div`
  width: calc(100% - 30%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & p {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #f5f5f5;
    margin-bottom: 15px;
  }
`;

export const CooperateSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #18bbfe;
`;
