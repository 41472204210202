import React from "react";
import { CopyModalWrapper, CopyModalContent } from "./index.style";
import CopyButton from "src/components/shared/buttons/CustomButton";
import CovertIcon from "src/assets/buttons/btn_convert.png";
import { ProductCardIcon } from "src/components/products/index.style";
import { lineLink } from "src/common/outside-links";

function CopyModal() {
  const handleClick = () => window.open(lineLink, "_blank");
  return (
    <CopyModalWrapper>
      <CopyModalContent>
        <p>會員帳號：</p>
        <p>我要兌換的是：</p>
        <p>商品收件人：</p>
        <p>商品收件地：</p>
        <p>收件人聯絡電話：</p>
      </CopyModalContent>
      <CopyButton name="前往兌換"  secondary onClick={handleClick}>
        <ProductCardIcon src={CovertIcon} alt="copy" width="20px" height="20px" />
      </CopyButton>
    </CopyModalWrapper>
  );
}

export default CopyModal;
