import React from "react";
import { CooperateWrapper, CooperateSpan } from "./index.style";
import CooperateButton from "src/components/shared/buttons/CustomButton";
import CooperateIcon from "src/assets/buttons/btn_cooperate.png";
import { ProductCardIcon } from "src/components/products/index.style";
import { lineLink } from "src/common/outside-links";

function CooperateContent() {
  const handleClick = () => window.open(lineLink, "_blank");
  return (
    <CooperateWrapper>
      <p>是否為本平台客戶：</p>
      <p>
        客戶帳號： <CooperateSpan>(如無帳號則不需理會)</CooperateSpan>
      </p>
      <p>
        您想上架的產品：<CooperateSpan>(越詳細越好)</CooperateSpan>
      </p>
      <p>您的店面地址：</p>
      <p>您的Line ID：</p>
      <p>您的聯絡電話：</p>
      <CooperateButton name="前往合作" secondary={false} onClick={handleClick}>
        <ProductCardIcon src={CooperateIcon} alt="cooperate" width="20px" height="20px" />
      </CooperateButton>
    </CooperateWrapper>
  );
}

export default CooperateContent;
