import React, { useState } from "react";
import { CooperateWrapper, CooperateTitle, CooperateContentWrapper } from "./index.style";
import CovertDialog from "src/components/shared/popup/CustomDialog";
import CooperateModal from "src/components/shared/cooperateModal";
import CooperateButton from "src/components/shared/buttons/CustomButton";
import covertIcon from "src/assets/buttons/btn_convert.png";
import useCopy from "src/hooks/useCopy";

function CooperateContent(props) {
  const { lastDialog } = props;
  const [cooperateOpen, setCooperateOpen] = useState(false);
  const { onCopy } = useCopy();
  const context = `是否為本平台客戶：\n客戶帳號：\n您想上架的產品：\n您的店面地址：\n您的Line ID：\n您的聯絡電話：\n`;

  const handleCooperateOpen = () => {
    onCopy(context);
    setCooperateOpen(true);
  };
  const handleCooperateClose = () => {
    setCooperateOpen(false);
    lastDialog(false);
  };

  return (
    <CooperateWrapper>
      {/* <CooperateTitle>合作規章</CooperateTitle> */}
      <CooperateContentWrapper>
        <p>本商城所有商品皆只提供飛達平台客戶使用兌換</p>
        <p>飛達幣拿法，可透過平台返利活動拿取</p>
        <p>1紅利點數=1 飛達幣</p>
        <p>您可兌換你喜歡的商品，或是提供意見喜歡的商品架上</p>
      </CooperateContentWrapper>
      {/* <CooperateButton name="複製格式" secondary={true} image={covertIcon} onClick={handleCooperateOpen} /> */}
      <CovertDialog
        open={cooperateOpen}
        onClose={handleCooperateClose}
        handleClose={handleCooperateClose}
        width="370px"
        height="370px"
        title="複製格式成功！"
      >
        <CooperateModal />
      </CovertDialog>
    </CooperateWrapper>
  );
}

export default CooperateContent;
