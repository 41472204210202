import React from "react";
import { CustomButtonWrapper } from "./index.style";

function CustomButton(props) {
  const { children, name, secondary, mb, onClick } = props;
  return (
    <>
      {secondary ? (
        <CustomButtonWrapper secondary mb={mb} onClick={onClick}>
          {children}
          {name}
        </CustomButtonWrapper>
      ) : (
        <CustomButtonWrapper mb={mb} onClick={onClick}>
          {children}
          {name}
        </CustomButtonWrapper>
      )}
    </>
  );
}

export default CustomButton;
