import styled from "styled-components";
import { Tabs, Tab, Box } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import tabBarPng from "src/assets/tabs/tabs_bar.jpg";
import { deviceSize } from "src/utils/deviceSize";

export const ProductsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabImage = styled.img`
  width: 60px;
  height: 60px;
`;

export const TabBackground = muiStyled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "110px",
  backgroundPosition: "center",
  background: "linear-gradient(0deg, #004B76 36%, #00E9CD 184%)",
  borderRadius: '30px'
});

export const TabContainer = muiStyled(Tabs)({
  alignItems: "center",
});


export const TabButtons = muiStyled(Tab)({
  color: "#f5f5f5",
  border: "none",
  fontSize: "15px",
  "&:hover": {
    color: "#00C2FF",
    fontSize: "17px",
    transform: "scale(1.1)",
    transitionDuration: "500ms",
  },
  "&.Mui-selected": {
    color: "#00C2FF",
    backgroundColor: "rgba(5, 18, 40, 0.5)",
  },
});

export const TabPanelWrapper = styled.div`
  width: 100%;
  // height: 100vh;
  // overflow-y: scroll;
  // ::-webkit-scrollbar {
  //   width: 4px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: linear-gradient(
  //     180deg,
  //     #003f6e 0%,
  //     rgba(1, 64, 111, 0.994792) 0.01%,
  //     rgba(24, 187, 254, 0.984528) 0.02%,
  //     #003f6e 100%
  //   );
  // }
  // ::-webkit-scrollbar-track {
  //   background: #2c4c80;
  // }
`;

export const ProductCardContainer = styled.div`
  width: 220px;
  // height: 361px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  // background: #183058;
  // box-shadow: 0px 0px 4px 4px rgba(24, 187, 254, 0.7);
  border: 2px #B9B9B9 solid;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: ${deviceSize.mobile}) {
    width: 168px;
  }
  @media (max-width: 330px) {
    width: 140px;
  }
`;

export const ProductCardImage = styled.img`
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  object-fit: contain;
  @media (max-width: ${deviceSize.mobile}) {
    height: 186px;
  } ;
`;

export const ProductCardContent = styled.div`
  width: 100%;
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  color: #f5f5f5;
  font-size: 16px;
  border-top: 2px #B9B9B9 solid;
  background: white;

  @media (max-width: ${deviceSize.mobile}) {
    font-size: 14px;
  }
`;

export const ProductCardName = styled.p`
  padding: 0px 6px 12px 6px;
  color: #203E5F;
  font-size: 16px;
  font-weight: 900;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
`;
// 預留文字換行
export const ProductCardPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-overflow: ellipsis;
`;

export const ProductCardDeepPriceWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${deviceSize.mobile}) {
    flex-direction: column;
  }
`;

export const ProductCardExchangeTime = styled.div`
  width: 100%;
  padding: 5px 0px;
  background-color: #9B9D9D;
  color: white;
  text-align: center;
`;


export const ProductCardPrice = styled.p`
  color: #EA7F00;
  font-size: 20px;
  @media (max-width: ${deviceSize.mobile}) {
    font-size: 16px;
  }
`;

export const ProductCardOriginPrice = styled.p`
  color: #B9B9B9;
  font-size: 12px;
`;

export const ProductCardIcon = styled.img`
  margin-right: 4px;
`;
