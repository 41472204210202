import styled from "styled-components";

export const CopyModalWrapper = styled.div`
  width: calc(100% - 30%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CopyModalContent = styled.div`
  width: 100%;
  margin-bottom: 15px;

  & p {
    font-size: 14px;
    font-weight: 500;
    color: #007DBE;
    margin-bottom: 15px;
  }
`;
