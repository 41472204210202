import React from "react";
import { FooterContainer, SponsorButton } from "./index.style";
import { sponsorsLink } from "src/common/outside-links";
import footerLogo from "src/assets/footer/FEIDA-LOGO.png";

function Footer() {
  const handleClick = () => window.open(sponsorsLink, "_blank");
  return (
    <FooterContainer>
      <SponsorButton onClick={handleClick}>
        <img src={footerLogo} alt="LOGO" width={80} height={45} />
        <div>贊助商:FEIDA365</div>
      </SponsorButton>
    </FooterContainer>
  );
}

export default Footer;
