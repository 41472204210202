import React from "react";
import { CustomSmButtonWrapper } from "./index.style";

function CustomSmButton(props) {
  const { name, secondary, onClick } = props;

  return (
    <>
      {secondary ? (
        <CustomSmButtonWrapper secondary onClick={onClick}>
          {name}
        </CustomSmButtonWrapper>
      ) : (
        <CustomSmButtonWrapper onClick={onClick}>{name}</CustomSmButtonWrapper>
      )}
    </>
  );
}

export default CustomSmButton;
