import styled from "styled-components";
import bgImg from "src/assets/common/FDbg.jpg";
import { deviceSize } from "src/utils/deviceSize";

export const PublicLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${bgImg});
`;

export const MainLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
`;
