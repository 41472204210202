const useCopy = () => {
  const onCopy = async (context) => {
    try {
      await window.navigator.clipboard.writeText(context);
    } catch (err) {
      console.log("Failed to copy:", err);
    }
  };
  return { onCopy };
};

export default useCopy;
