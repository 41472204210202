import React, { useState } from "react";
import PropTypes from "prop-types";
import covertIcon from "src/assets/buttons/btn_convert.png";
import priceIcon from "src/assets/products/ProductPriceIcon.png";
import popupCartIcon from "src/assets/modal/popup_cart.png";
import popupPriceIcon from "src/assets/modal/popup_price.png";
import CopyIcon from "src/assets/buttons/btn_copy.png";
import {
  ProductCardContainer,
  ProductCardContent,
  ProductCardImage,
  ProductCardPriceWrapper,
  ProductCardPrice,
  ProductCardName,
  ProductCardIcon,
  ProductCardOriginPrice,
  ProductCardExchangeTime,
  ProductCardDeepPriceWrapper
} from "./index.style";
import {
  DialogContentTitle,
  CovertItemWrapper,
  CovertItemImage,
  CovertDetail,
  CovertItemTextWrapper,
  CovertItem,
  CovertWrapper,
} from "src/components/shared/popup/index.style";

import CovertButton from "src/components/shared/buttons/CustomLgButton";
import CopyButton from "src/components/shared/buttons/CustomButton";
import CovertDialog from "src/components/shared/popup/CustomDialog";
import CopyDialog from "src/components/shared/popup/CustomDialog";
import CopyModal from "src/components/shared/copyModal";
import useCopy from "src/hooks/useCopy";

function ProductCard(props) {
  const { image, name, price, url, exchangeTime, originPrice  } = props;
  console.log(originPrice)
  const [covertOpen, setCovertOpen] = useState(false);
  const [copyOpen, setCopyOpen] = useState(false);
  const { onCopy } = useCopy();
  const context = `會員帳號：\n我要兌換的是：\n商品收件人：\n商品收件地：\n收件人聯絡電話：\n`;

  const handleCovertOpen = () => {
    if (url) {
      window.open(url, '_blank').focus();

      return;
    }

    setCovertOpen(true);
  };
  const handleCovertClose = () => setCovertOpen(false);

  const handleCopyOpen = () => {
    onCopy(context);
    setCopyOpen(true);
  };
  const handleCopyClose = () => {
    setCopyOpen(false);
    handleCovertClose();
  };

  return (
    <ProductCardContainer>
      <ProductCardImage src={image} />
      <ProductCardContent>
        <ProductCardName>{name}</ProductCardName>
        <ProductCardPriceWrapper>
          <ProductCardIcon src={priceIcon} alt="price icon" width="20px" height="20px" />

          <ProductCardDeepPriceWrapper>
            <ProductCardPrice>{price}</ProductCardPrice>
            <ProductCardOriginPrice>{`(市價${originPrice})`}</ProductCardOriginPrice>
          </ProductCardDeepPriceWrapper>

        </ProductCardPriceWrapper>
      </ProductCardContent>
      <ProductCardExchangeTime>♥歷史兌換人數{exchangeTime}</ProductCardExchangeTime>
      <CovertButton
        name="我要兌換"
        secondary={false}
        image={covertIcon}
        handleClick={handleCovertOpen}
      />

      <CovertDialog
        title='兌換商品'
        open={covertOpen}
        onClose={handleCovertClose}
        handleClose={handleCovertClose}
        width="370px"
        height="370px"
      >
        <CovertWrapper>

          <CovertItemWrapper>
            <CovertItemImage src={`${image}`} alt="img" width={100} height={100} />
            <CovertDetail>
              <CovertItem>
                <img src={popupCartIcon} alt="cart" width={20} height={20} />
                <CovertItemTextWrapper>
                  <span>品名：</span>
                  <p>{name}</p>
                </CovertItemTextWrapper>
              </CovertItem>

              <CovertItem>
                <img src={popupPriceIcon} alt="price" width={20} height={20} />
                <CovertItemTextWrapper>
                  <span>飛達幣：</span>
                  <p>{price}</p>
                </CovertItemTextWrapper>
              </CovertItem>
            </CovertDetail>
          </CovertItemWrapper>

          <CopyButton name="複製格式" secondary={true} mb={10} onClick={handleCopyOpen}>
            <ProductCardIcon src={CopyIcon} alt="copy" width="20px" height="20px" />
          </CopyButton>
        </CovertWrapper>

        <CopyDialog
          open={copyOpen}
          onClose={handleCopyClose}
          handleClose={handleCopyClose}
          width="370px"
          height="370px"
          title="格式複製成功！"
        >
          <CopyModal />
        </CopyDialog>
      </CovertDialog>
    </ProductCardContainer>
  );
}

ProductCard.prototype = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default ProductCard;
